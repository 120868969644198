* {
  box-sizing: border-box;
  user-select: none;
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For Internet Explorer and Edge yeah */
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox try */
}

/* Remove any scrolling */
html,
body,
iframe {
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  background-color: #91c3de;
}

.main-container {
  margin: 0px !important;
  padding: 0px !important;
}

.logo-area {
  background-color: #91c3de !important;
}

.pageheader:before {
  background-color: #91c3de !important;
}

html,
body,
#root {
  height: 100%; /* Ensuring the body takes full height */
  width: 100vw; /* Sets the width to full viewport width */
  margin: 0 !important; /* Removes default margin */
  padding: 0 !important; /* Removes default padding */
  overflow-x: hidden; /* Prevents horizontal scroll */
  overflow: hidden !important;
  overscroll-behavior: none;
  touch-action: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

#root {
  height: 100vh; /* Ensures the app container also takes full height */
  width: 100vw; /* Ensures the app container also takes full width */
  display: flex; /* Optional: for flexbox layouts */
  flex-direction: column; /* Optional: for flexbox layouts */
  padding: 0px;
  margin: 0px;
  min-height: 100vh;
}

.mobile-container {
  position: 'absolute';
  top: 0;
  left: 0;
  width: '100%';
  height: '100%';
}

h1 {
  margin: 0 !important; /* Removes default margin */
  padding: 0 !important; /* Removes default padding */
}

h3 {
  margin: 0 !important; /* Removes default margin */
  padding: 0 !important; /* Removes default padding */
}

.body {
  display: flex;
  font-size: 14px; /* Adjust the size as needed */
}

.center-column {
  display: flex;
  flex-direction: column;
  flex: 1; /* Allows equal flex growth */
  min-width: 125px; /* Ensures it never gets smaller than 125px */
}

.left-column {
  display: flex;
  flex-direction: column;
  position: absolute;
  flex: 1; /* Allows equal flex growth */
  min-width: 75px; /* Ensures it never gets smaller than 125px */
  max-width: 75px;
  right: 0px;
  top: -175px;
}

.right-column {
  padding: 0; /* Adjust or remove padding as needed */
  overflow: visible;
  /* If there's any margin that affects alignment, adjust it here as well */
}

.share {
  cursor: pointer; /* Indicates the option is clickable */
}

.dice {
  padding: 0px;
  background-color: #efeee7; /* Slightly different shade for each div for visibility */
}

/* Hide the mobile footer on desktop */
@media (orientation: portrait) {
  .dice-icon {
    position: absolute;
    top: -115px; /* Adjust this to ensure it sits right above the footer */
    left: 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }

  .rectangle,
  .share,
  .mic {
    margin-bottom: 5px;
    padding-bottom: 10px;
    margin-top: 5px;
    padding-top: 10px;
    font-family: 'jost'; /* The font-family name might vary based on Adobe's settings */
    font-size: 14px; /* Adjust the size as needed */
  }

  .dots-more {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; /* Indicates the option is clickable */
    margin-top: 5px;
  }

  .desktop-icons-container {
    display: none;
  }

  .desktop-share {
    display: none;
  }

  .desktop-screenshot {
    display: none;
  }
}

@media (orientation: landscape) {
  .dots-more,
  .share {
    display: none;
  }
}

.dice-icon img {
  width: 90px; /* Adjust to your preferred size */
  height: 90px; /* Keep this to maintain the aspect ratio */
}

.heart {
  position: absolute;
  left: 95px;
  top: 80px;
}

.rectangle {
  /* Initial size at 350px viewport */
  width: 39px;
  height: 65px;
  align-items: center;
  justify-content: center; /* Center the SVG in the rectangle */
  /* Ensure the aspect ratio is maintained */
  display: flex;
  /* Additional styling for rectangles */
  background-color: #efeee7; /* Example background color */
  border-radius: 3px; /* Adjust as needed */
}

.dot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0px;
  height: 20px;
}

.scroll-indicator {
  background-color: #e9f3f8; /* Adjusted to your design */
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-radius: 0 0 6px 6px; /* Adjusted to your design for consistency */
  /* Reduce width and centrally align the div */
  margin-bottom: 0;
  margin: 0 auto; /* Centers the div */
  margin-left: 15px;
  margin-right: 5px;
  box-sizing: border-box; /* Ensures border is included in width calculation */
  display: flex; /* Enables flexbox layout */
  align-items: center; /* Vertically centers the content within the container */
  justify-content: center; /* Horizontally centers the content in the container */
  gap: 18px; /* Optional: adds space between each item */
  height: 20px;
}

.decorative-element {
  background-color: #e9f3f8;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  border-radius: 0 0 0 10px;
  width: calc(100% - 10px);
  padding: 2px;
  margin: 0 auto;
  margin-right: 0; /* Ensures it extends to the right edge */
  margin-bottom: 30px;
  box-sizing: border-box;
}

.top-content,
.bottom-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Fill the footer space */
}

.scrollable-footer-wrapper {
  transition: height 0.3s ease;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.scrollable-footer {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for touch devices */
  scrollbar-width: none; /* For Firefox */
  width: 95%;
  margin: 0 auto;
}

@media (min-width: 420px) and (min-height: 730px) {
  /* pro max */
  .scrollable-footer {
    margin-top: 5px;
    margin-bottom: 15px;
  }
}

@media (min-width: 370px) and (min-height: 620px) and (max-width: 379px), (min-width: 370px) and (min-height: 620px) and (max-height: 629px) {
  /* SE */
  .scrollable-footer {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media (min-width: 380px) and (min-height: 630px) and /* Regular */ (max-width: 419px), (min-width: 380px) and (min-height: 630px) and (max-height: 729px) {
  .scrollable-footer {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.scrollable-footer::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.icon-container img,
.scrollable-footer img {
  user-select: none; /* Prevent text/image selection */
  pointer-events: none; /* Ignore pointer events for drag */
  touch-action: none; /* Prevent touch gestures like scrolling or dragging */
  max-width: 42px;
  max-height: 42px;
  width: auto;
  height: auto;
}

@media (min-width: 420px) and (min-height: 730px) {
  /* pro max */
  /* pro max */
  .footer-option {
    flex: 0 0 auto; /* Do not grow, do not shrink, auto basis */
    cursor: pointer; /* Indicates the option is clickable */
    user-select: none;
    border-left: 0px solid black; /* Outline the bottom */
    background-color: #eeeeef; /* Background color of the option */
    display: flex; /* Use flex to center the text inside the option */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    font-size: larger; /* Larger text size */
    padding: 12px 12px; /* Padding to ensure the text does not touch the borders */
    margin: 5px;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    font-family: 'roc-grotesk-wide', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 0.3em; /* Adjust the size as needed */
    letter-spacing: 0.15em; /* Adds a bit more spacing between each letter */
    color: #808082 !important;
    border-radius: 10px;
  }
}

@media (min-width: 370px) and (min-height: 620px) and (max-width: 379px), (min-width: 370px) and (min-height: 620px) and (max-height: 629px) {
  /* SE */

  .footer-option {
    flex: 0 0 auto; /* Do not grow, do not shrink, auto basis */
    cursor: pointer; /* Indicates the option is clickable */
    user-select: none;
    border-left: 0px solid black; /* Outline the bottom */
    background-color: #eeeeef; /* Background color of the option */
    display: flex; /* Use flex to center the text inside the option */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    font-size: larger; /* Larger text size */
    padding: 10px 10px; /* Padding to ensure the text does not touch the borders */
    margin: 5px;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    font-family: 'roc-grotesk-wide', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 0.18em; /* Adjust the size as needed */
    letter-spacing: 0.15em; /* Adds a bit more spacing between each letter */
    color: #808082 !important;
    border-radius: 10px;
  }
}

@media (min-width: 380px) and (min-height: 630px) and (max-width: 419px),
  (min-width: 380px) and (min-height: 630px) and (max-height: 729px) and (orientation: portrait) {
  /* Regular */

  .footer-option {
    flex: 0 0 auto; /* Do not grow, do not shrink, auto basis */
    cursor: pointer; /* Indicates the option is clickable */
    user-select: none;
    border-left: 0px solid black; /* Outline the bottom */
    background-color: #eeeeef; /* Background color of the option */
    display: flex; /* Use flex to center the text inside the option */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    font-size: larger; /* Larger text size */
    padding: 10px 10px; /* Padding to ensure the text does not touch the borders */
    margin: 5px;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    font-family: 'roc-grotesk-wide', sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 0.2em; /* Adjust the size as needed */
    letter-spacing: 0.15em; /* Adds a bit more spacing between each letter */
    color: #808082 !important;
    border-radius: 10px;
  }
}

.footer-option h1 {
  color: #808082 !important;
}

.add-to-cart {
  background-color: #6186f7;
  color: #faf8f3 !important;
  text-align: center;
  width: 95%;
  cursor: pointer; /* Indicates the option is clickable */
  display: flex; /* Adds flexbox layout */
  align-items: center; /* Aligns children (text) vertically in the center */
  justify-content: center; /* Centers content horizontally, works well with text-align: center */
  border-radius: 30px;
}

@media (min-width: 420px) and (min-height: 730px) and (orientation: portrait) {
  /* MAX */
  .add-to-cart {
    padding: 5px;
    letter-spacing: 0.15em; /* Adds a bit more spacing between each letter */
    height: 48px;
    margin: 0 auto;
    margin-bottom: 15px;
  }

  .add-to-cart-min-footer {
    margin-top: 8px;
  }

  .add-to-cart h2 {
    color: #faf8f3 !important;
    margin: 0 !important; /* Removes default margin */
    padding: 0 !important; /* Removes default padding */
    font-size: 0.7em !important;
  }
}

@media (min-width: 370px) and (min-height: 620px) and (max-width: 379px),
  (min-width: 370px) and (min-height: 620px) and (max-height: 629px) and (orientation: portrait) {
  /* SE */
  .add-to-cart {
    padding: 5px;
    letter-spacing: 0.15em; /* Adds a bit more spacing between each letter */
    height: 36px;
    margin: 0 auto;
    margin-bottom: 0px;
  }

  .add-to-cart-min-footer {
    margin-top: 8px;
  }

  .add-to-cart h2 {
    color: #faf8f3 !important;
    margin: 0 !important; /* Removes default margin */
    padding: 0 !important; /* Removes default padding */
    font-size: 0.7em !important;
  }
}

@media (min-width: 380px) and (min-height: 630px) and (max-width: 419px),
  (min-width: 380px) and (min-height: 630px) and (max-height: 729px) and (orientation: portrait) {
  /* Regular iPhones */
  .add-to-cart {
    padding: 5px;
    letter-spacing: 0.15em; /* Adds a bit more spacing between each letter */
    height: 36px;
    margin: 0 auto;
    margin-bottom: 0px;
  }

  .add-to-cart-min-footer {
    margin-top: 8px;
  }

  .add-to-cart h2 {
    color: #faf8f3 !important;
    margin: 0 !important; /* Removes default margin */
    padding: 0 !important; /* Removes default padding */
    font-size: 0.7em !important;
  }
}

.add-to-cart.loading {
  position: relative;
  color: transparent; /* Hide text */
}

.add-to-cart.loading::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #ccc, #faf8f3, #ccc);
  animation: loading-bar 1.5s infinite;
  border-radius: 30px; /* Match the button shape */
}

.desktop-footer-extra-padding {
  padding-bottom: 425px;
}

@keyframes loading-bar {
  0% {
    background-position: -200px;
  }
  100% {
    background-position: 200px;
  }
}

.footer-div {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px; /* Adjust to control space within footer */
  display: flex;
  flex-direction: column;
  gap: 10px; /* Controls space between child elements */
  box-sizing: border-box;
  background-color: #faf8f3;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.footer-option.active {
  background-color: #d1d1ce; /* Highlight for the active option */
}

.footer {
  display: flex;
  justify-content: space-around;
  background-color: #a2a2a2;
  margin: 0; /* Remove default margins if any */
}

.footer-draggable-area {
  touch-action: none;
  overscroll-behavior: none;
}

.selection {
  overflow-y: auto; /* Enable scrolling if content exceeds this height */
  background-color: #faf8f3;
  padding: 0px 0px 0px 0px;
  margin: 0px;
}

.selected-option {
  display: flex;
  margin-left: auto;
  padding-right: 15px;
  font-family: 'jost'; /* The font-family name might vary based on Adobe's settings */
  font-weight: semibold; /* Ensure it is set to use the bold style if available */
  font-size: 1.1em; /* Adjust the size as needed */
}

.selected-color {
  flex-grow: 1;
  margin: 0px 0px 0px 16px;
  /* Style as needed */
}

.option-header {
  font-family: 'roc-grotesk-wide', sans-serif !important;
  font-weight: 700 !important;
  font-style: normal;
  font-size: 0.9em; /* Adjust the size as needed */
  letter-spacing: 0.05em; /* Adds a bit more spacing between each letter */
  margin: 0 0 0 15px;
}

/* Default position for larger screens */
canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

/* Adjust position for screens 400px wide or less */
@media (max-width: 400px) {
  canvas {
    position: absolute;
    top: 0px; /* Adjust as needed */
    left: 0px; /* Adjust as needed */
    width: 100vw; /* Adjust width for smaller screens */
    height: 100vh; /* Adjust height for smaller screens */
  }
}

@media (max-width: 400px) {
  .selected-option {
    padding-right: 0px;
    font-size: 0.8em; /* Adjust the size as needed */
  }

  .color-circle {
    width: 28px;
    height: 28px;
    margin-right: 4.5px;
  }
}

@media (min-width: 420px) and (min-height: 730px) {
  /* MAX */

  .header-and-selected {
    display: flex;
    align-items: center;
    gap: 10px; /* Adjusts spacing between header and selected color name */
    margin-top: 0px;
  }

  .header-and-selected h3 {
  }
  .color-options-container {
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    margin-top: 10px;
    height: 50px;
  }

  .color-options {
    margin: 0px;
    padding: 0px;
  }

  .bottom-screen-options-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px; /* Adjust spacing as needed */
    overflow: visible; /* Prevents squishing by allowing overflow */
    scrollbar-width: none; /* Firefox */
    padding-left: 15px;
    padding-top: 13px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    margin-top: 10px;
    height: 50px;
  }

  .screen-options-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px; /* Adjust spacing as needed */
    overflow: visible; /* Prevents squishing by allowing overflow */
    scrollbar-width: none; /* Firefox */
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 13px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    margin-top: 10px;
    height: 50px;
  }
  .microphone-options-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px; /* Adjust spacing as needed */
    overflow: visible; /* Prevents squishing by allowing overflow */
    scrollbar-width: none; /* Firefox */
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 13px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    margin-top: 10px;
    height: 50px;
  }
}

@media (min-width: 370px) and (min-height: 620px) and (max-width: 379px), (min-width: 370px) and (min-height: 620px) and (max-height: 629px) {
  /* SE */

  .header-and-selected {
    display: flex;
    align-items: center;
    gap: 10px; /* Adjusts spacing between header and selected color name */
    margin-top: 5px;
  }

  .header-and-selected h3 {
  }
  .color-options-container {
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 10px;
    margin-bottom: 3px;
    margin-top: 3px;
    height: 50px;
  }

  .color-options {
    margin: 0px;
    padding: 0px;
  }

  .bottom-screen-options-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px; /* Adjust spacing as needed */
    overflow: visible; /* Prevents squishing by allowing overflow */
    scrollbar-width: none; /* Firefox */
    padding-left: 15px;
    padding-top: 13px;
    padding-bottom: 10px;
    margin-bottom: 3px;
    margin-top: 3px;
    height: 50px;
  }

  .screen-options-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px; /* Adjust spacing as needed */
    overflow: visible; /* Prevents squishing by allowing overflow */
    scrollbar-width: none; /* Firefox */
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 13px;
    padding-bottom: 10px;
    margin-bottom: 3px;
    margin-top: 3px;
    height: 50px;
  }

  .microphone-options-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px; /* Adjust spacing as needed */
    overflow: visible; /* Prevents squishing by allowing overflow */
    scrollbar-width: none; /* Firefox */
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 13px;
    padding-bottom: 10px;
    margin-bottom: 3px;
    margin-top: 3px;
    height: 50px;
  }
}

@media (min-width: 380px) and (min-height: 630px) and (max-width: 419px), (min-width: 380px) and (min-height: 630px) and (max-height: 729px) {
  /* Regular iPhones */

  .header-and-selected {
    display: flex;
    align-items: center;
    gap: 10px; /* Adjusts spacing between header and selected color name */
    margin-top: 5px;
  }

  .header-and-selected h3 {
  }
  .color-options-container {
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 10px;
    margin-bottom: 8px;
    margin-top: 3px;
    height: 50px;
  }

  .color-options {
    margin: 0px;
    padding: 0px;
  }

  .bottom-screen-options-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px; /* Adjust spacing as needed */
    overflow: visible; /* Prevents squishing by allowing overflow */
    scrollbar-width: none; /* Firefox */
    padding-left: 15px;
    padding-top: 13px;
    padding-bottom: 10px;
    margin-bottom: 8px;
    margin-top: 3px;
    height: 50px;
  }

  .screen-options-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px; /* Adjust spacing as needed */
    overflow: visible; /* Prevents squishing by allowing overflow */
    scrollbar-width: none; /* Firefox */
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 13px;
    padding-bottom: 10px;
    margin-bottom: 8px;
    margin-top: 3px;
    height: 50px;
  }

  .microphone-options-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px; /* Adjust spacing as needed */
    overflow: visible; /* Prevents squishing by allowing overflow */
    scrollbar-width: none; /* Firefox */
    padding-left: 15px;
    padding-right: 10px;
    padding-top: 13px;
    padding-bottom: 10px;
    margin-bottom: 8px;
    margin-top: 3px;
    height: 50px;
  }
}

.color-options-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 8px; /* Adjust spacing as needed */
  overflow-x: auto; /* Horizontal scrolling only */
  overflow-y: hidden; /* Disable vertical scrolling */
  scrollbar-width: none; /* Firefox */
}

.color-options-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.fade-in {
  animation: fadeIn 0.5s ease-in;
}

.fade-out {
  animation: fadeOut 0.5s ease-out forwards; /* 'forwards' keeps the element in the final state of the animation */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.dots-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: -25px;
  left: 0;
}

.control-dots {
  display: flex;
  gap: 14px; /* Adjust to control space between dots */
  justify-content: center;
  align-items: center;
}

.dots,
.dots-side,
.dots-back,
.dots-crazy {
  width: 12px; /* Control dot size here */
  height: 12px;
  background-color: #d1d1ce;
  border-radius: 50%; /* Makes the element circular */
  cursor: pointer;
}

.dots.active,
.dots-side.active,
.dots-back.active,
.dots-crazy.active {
  background-color: black; /* Active dot color */
}

.dots-more,
.share {
  pointer-events: auto; /* Ensure these elements are clickable */
}

.rectangles-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Keep items aligned to the right */
  width: auto; /* Shrink to fit content */
  padding-right: 25px;
  transform: translateY(-30vh); /* Keeps position */
  pointer-events: none; /* Prevents the container from blocking clicks */
}

.color-circle {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
  margin-right: 4.5px;
}

.color-options-container .color-circle:nth-child(4) {
  border: 1px solid black; /* Black outline */
}

.color-circle.active {
  position: relative;
}

.color-circle.active::after {
  content: '';
  position: absolute;
  top: -4px; /* Adjust to control the spacing */
  left: -4px;
  width: calc(100% + 8px); /* Matches spacing */
  height: calc(100% + 8px);
  border: 2.5px solid #91c3de; /* Active border color */
  border-radius: 50%;
}

.app-container {
  display: flex; /* Optional: for flexbox layouts */
  flex-direction: column; /* Optional: for flexbox layouts */
  position: relative; /* Adjust if necessary based on your layout */
  max-height: 100vh;
  height: 100vh; /* Adjust 60px to the actual height of your nav bar */
  width: 100vw;
  padding: 0px;
  margin: 0px;
  overflow: visible; /* Instead of hidden/auto */
}

.maincontainer {
  display: flex;
  flex-direction: column; /* Stack the containers vertically */
  height: 90vh; /* Full viewport height */
  padding: 0px;
  margin: 0px;
  overflow: visible; /* Instead of hidden/auto */
}

.modelcontainer {
  flex: 0 0 60%; /* Takes up 65% of the screen */
  height: 60vh;
  position: relative;
  padding: 0px;
  margin: 0px;
  overflow: visible; /* Instead of hidden/auto */
}

.canvas {
  overflow: visible; /* Instead of hidden/auto */
}

.bottomhalfcontainer {
  position: relative;
  overflow: visible; /* Instead of hidden/auto */
  padding: 0px;
  margin: 0px;
}

.content {
  position: relative;
  z-index: 1; /* Ensures it is above the canvas */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Adjust these styles based on your flexbox layout */
}

.outer-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden; /* Prevents any overflow from the content */
}

.screen-type-option {
  display: flex;
  align-items: center; /* Center items vertically */
  margin-bottom: 0px; /* Add vertical space between items */
}

.icon-container {
  position: relative; /* Position the container relatively */
  cursor: pointer; /* Indicates the option is clickable */
  overflow: visible;
}

.screen-type-icon {
  width: 45px;
  height: 45px;
}

.highlight-circle {
  z-index: 1; /* Move the highlight above the icon */
  content: '';
  position: absolute;
  top: -6px; /* Adjust spacing around the element */
  left: -6px;
  overflow: visible;
}

.screen-type-option img {
  width: 42px;
  height: 42px;
}

.screen-type-label {
  margin-left: 10px; /* Add some space between the icon and text */
  font-family: 'jost', semibold; /* The font-family name might vary based on Adobe's settings */
}

.arrow-left {
  cursor: pointer; /* Indicates the option is clickable */
}

.arrow-right {
  cursor: pointer; /* Indicates the option is clickable */
}

.scroll-text {
  font-family: 'jost'; /* The font-family name might vary based on Adobe's settings */
  font-size: 15px; /* Adjust the size as needed */
}

/* Additional CSS for hover effect */
.arrow-left,
.arrow-right {
  transition: transform 0.3s ease-in-out; /* Smooth transition for transform */
  cursor: pointer; /* Changes the cursor to indicate it's clickable */
}

.arrow-left:hover,
.arrow-right:hover {
  transform: scale(1.2); /* Increases the sizef to 120% when hovered */
}

.footer-option {
  transition: font-size 0.3s ease; /* Smooth transition for font size */
  cursor: pointer; /* Change cursor to indicate clickable */
}

.bottom-screen-type-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.bottom-screen-type-option:hover {
}

.bottom-screen-type-option.active {
}

.bottom-screen-type-icon {
  width: 30%;
  height: 42px;
  margin-right: 10px;
}

.bottom-screen-type-label {
  font-size: 14px;
  font-family: 'jost'; /* The font-family name might vary based on Adobe's settings */
  font-size: 14px; /* Adjust the size as needed */
}

.microphone-type-option {
  display: flex;
  align-items: center; /* Aligns items vertically */
  cursor: pointer;
  padding: 0px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.microphone-type-option:hover {
}

.icon-label-container {
  display: flex;
  align-items: center; /* Ensure the icon and label are aligned vertically */
  gap: 10px; /* Space between icon and label */
}

.microphone-type-icon {
  width: 45px;
  height: 45px;
  margin-right: 10px;
}

.heart-with-text {
  display: flex;
  align-items: center;
  gap: 8px; /* Adjust gap between icon and text */
  font-family: 'jost'; /* The font-family name might vary based on Adobe's settings */
  font-size: 14px; /* Adjust the size as needed */
}

.forcedrender {
  position: absolute;
  top: 0;
  left: 0;
  width: 300px;
  height: 300px;
  background-color: #000000;
}
.shopify-log-button {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  background-color: #333;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

/* Hide the mobile footer on desktop */
@media (orientation: landscape) {
  .mobile-footer {
    display: none;
  }
}

/* Default: hide desktop footer */
.desktop-footer {
  display: none;
}

@media (orientation: landscape) {
  .desktop-footer {
    display: block;
    position: fixed;
    top: 135px;
    right: 0 !important;
    width: 50vw; /* Half the viewport width */
    height: 100vh; /* Full viewport height */
    background-color: #faf8f3;
    overflow-y: auto; /* Scroll if content overflows */
    padding: 20px 40px; /* 20px vertical and 40px horizontal padding for blank space */
    padding-right: 5% !important;
    padding-left: 5% !important;
    z-index: 1;
  }

  /* Override for Firefox iOS */
  .firefox-ios {
    /* Force safe-area insets to zero or to your desired values */
    padding-top: 20px !important;
    padding-right: 40px !important;
    padding-bottom: 20px !important;
    padding-left: 40px !important;
  }
}

@media (orientation: landscape) {
  .bottom-screen-options-container,
  .microphone-options-container,
  .screen-options-container {
    flex-wrap: wrap;
    overflow-x: visible; /* remove horizontal scroll */
  }

  .option-content {
    height: auto;
  }

  .color-options-container {
    height: auto;
    padding-bottom: 10px; /* Adjust this value as needed */
    overflow: visible;
  }

  .color-options-container {
    flex-wrap: wrap;
    overflow-x: visible; /* remove horizontal scroll */
  }

  .footer-option {
    display: none;
  }
}

.desktop-row {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.desktop-color-container,
.desktop-screen-container {
  display: flex;
  flex-wrap: wrap; /* Allow circles to drop down when they hit the edge */
  gap: 10px;
}

.color-circle {
  width: 31px;
  height: 31px;
  border-radius: 50%;
  cursor: pointer;
}

/* Desktop add-to-cart button */

.desktop-add-to-cart-container {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 12%;
  background: #faf8f3; /* White background */
  color: #faf8f3 !important;
  padding: 10px 0; /* Adjust padding as needed */
  text-align: center;
  z-index: 1010; /* Ensure it covers content underneath */
  justify-content: center !important;
}

.canvas-container {
  width: 100vw; /* Default on mobile */
}

@media (orientation: landscape) {
  .canvas-container {
    width: 50vw !important; /* or any value that suits your desktop layout */
    font-family: 'jost'; /* The font-family name might vary based on Adobe's settings */
  }

  .product-card {
    padding: 16px;
    font-family: 'jost'; /* The font-family name might vary based on Adobe's settings */
  }

  /* Header row: title on the left, price on the right */
  .product-header .header-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  /* Main title (first row) */
  .product-title-page {
    flex: 1;
    min-width: 0; /* Allows wrapping */
    margin: 0;
    font-size: 2.2em;
    line-height: 1;
    white-space: normal; /* Allow text to wrap */
  }

  /* Main price */
  .product-price {
    flex-shrink: 0;
    width: 80px; /* Fixed width to reserve space */
    margin: 0;
    font-size: 1.2em;
    color: #000;
    text-align: right;
    font-family: 'jost'; /* The font-family name might vary based on Adobe's settings */
  }

  /* Secondary header (second row) */
  .product-subtitle {
    flex: 1;
    min-width: 0; /* Allows wrapping */
    margin: 0;
    font-size: 2.2em;
    line-height: 1;
    white-space: normal; /* Allow text to wrap */
    font-family: 'roc-grotesk-wide', sans-serif;
    font-weight: 700;
  }

  /* Secondary price */
  .product-second-price {
    flex-shrink: 0;
    width: 80px;
    margin: 0;
    font-size: 1.4em;
    color: #000;
    text-align: right;
  }

  /* Description paragraph */
  .product-description {
    margin-top: 8px;
    max-width: 85%;
  }

  /* Footer row with the two links */
  .product-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  .product-footer a {
    text-decoration: none;
    color: #000;
    font-size: 1.1em;
  }

  html,
  body,
  iframe {
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
    background-color: #faf8f3;
  }

  .main-container {
    margin: 0px !important;
    padding: 0px !important;
  }

  .logo-area {
    background-color: #faf8f3 !important;
  }

  .pageheader:before {
    background-color: #faf8f3 !important;
  }

  .add-to-cart {
    position: fixed;
    bottom: 25px; /* Adjust spacing from the bottom */
    left: 75vw; /* Center of right half (50vw to 100vw -> center s 75vw) */
    transform: translateX(-50%); /* Shift left by half of its own width */
    text-align: center;
    background-color: #6186f7;
    color: #faf8f3 !important;
    width: 30%; /* Adjust width as needed */
    cursor: pointer;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 30px;
    margin: 0 auto;
    padding-top: 1%;
    padding-bottom: 1%;
  }

  .add-to-cart h2 {
    color: #faf8f3 !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 1.15em !important;
    margin: 0 !important; /* Removes default margin */
    padding: 0 !important; /* Removes default padding */
  }

  .add-to-cart.loading {
    position: relative;
    color: transparent; /* Hide text */
    position: fixed;
  }

  .add-to-cart.loading::after {
    content: '';
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #ccc, #faf8f3, #ccc);
    animation: loading-bar 1.5s infinite;
    border-radius: 30px; /* Match the button shape */
  }

  .dice-icon {
    position: absolute;
    bottom: 35px; /* Adjust this to ensure it sits right above the footer */
    left: 35px;
    cursor: pointer;
    z-index: 10;
  }

  .desktop-icons-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Keep items aligned to the right */
    width: auto; /* Shrink to fit content */
    padding-left: 25px;
    transform: translateY(40vh); /* Keeps position */
    pointer-events: none; /* Prevents the container from blocking clicks */
    z-index: 1;
  }

  .desktop-share {
    margin-bottom: 5px;
    padding-bottom: 10px;
    margin-top: 5px;
    padding-top: 10px;
    font-family: 'jost'; /* The font-family name might vary based on Adobe's settings */
    font-size: 14px; /* Adjust the size as needed */
  }

  .desktop-screenshot {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; /* Indicates the option is clickable */
    margin-top: 5px;
  }

  .header-and-selected {
    display: flex;
    align-items: center;
    gap: 10px; /* Adjusts spacing between header and selected color name */
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 15px;
  }

  .selected-color {
    color: #a2a2a2 !important;
  }

  .bottom-screen-options-container {
    padding-bottom: 20px;
    margin-bottom: 25px;
  }
}

.footer-summary {
  padding: 10px;
  margin-top: 20px;
}

.footer-summary-row {
  margin-bottom: 1rem; /* space between rows */
  cursor: pointer; /* Indicates the option is clickable */
}

.footer-summary-header {
  margin: 0 0 0.5rem 0; /* a little space below the header */
  font-size: 1.5em;
  font-weight: bold;
  cursor: pointer; /* Indicates the option is clickable */
}

.footer-summary-selection {
  display: flex;
  align-items: center;
  font-size: 1em; /* adjust for body text */
  line-height: 1.4;
  cursor: pointer; /* Indicates the option is clickable */
}
